<script setup lang="ts">
import type { ILocation } from '@/types/types'
import type { AutoCompleteChangeEvent } from 'primevue/autocomplete'
import { SearchProcedureOrdenation, SearchProcedureOrdenationMapping } from '@/types/enums'

interface ILocationSearchResponse {
  items?: ILocation[]
  page?: number
  pages?: number
  size?: number
  total?: number

  city?: string
  state?: string
  stateFu?: string
  country?: string
  id?: string
  showName?: string
  slug?: string
  lat?: number
  lng?: number
  centerLat?: number
  centerLng?: number
  postalCode?: string
}

defineProps({
  placeholder: {
    type: String,
    default: 'Buscar por CEP ou Cidade',
  },
  label: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['selectLocation'])

const model = defineModel({
  type: String,
  default: '',
})

const term = ref<string>('')
const dTerm = refDebounced(term, 300, { maxWait: 600 })
const locationInvalid = ref<boolean>(false)

const termIsZipCodePart = computed(() => isZipCodePart(term.value))
const termIsZipCode = computed(() => isZipCode(term.value))

const mask = computed(() => {
  const regex = /^[0-9\-]+$/

  return regex.test(term.value) ? '#####-###' : ''
})

const { data: cities, clear } = useAsyncData<ILocation[]>('locationSearch', async () => {
  const term = dTerm.value?.trim()

  if (term === useLocation().value?.showName)
    return

  if (term.length < 2)
    return [] as ILocation[]

  if (termIsZipCodePart.value && !termIsZipCode.value)
    return [] as ILocation[]

  const response = await $fetch<ILocationSearchResponse>('/v1/recommendations/district', {
    baseURL: useRuntimeConfig().public.api.awsGateway,
    query: termIsZipCode.value ? { zipcode: term } : { city: term },
  })

  if (!termIsZipCode.value && response.items?.length) {
    return response.items as ILocation[]
  }

  if (termIsZipCode.value && response?.postalCode) {
    model.value = response?.postalCode

    emit('selectLocation')

    return [] as ILocation[]
  }

  if (termIsZipCode.value && !response?.postalCode) {
    throw new Error('Invalid location')
  }

  throw new Error('Not found')
}, {
  watch: [dTerm],
  transform: snakeToCamel,
})

function handleSelect(event: AutoCompleteChangeEvent) {
  model.value = event.value.slug

  clear()
  term.value = event.value.showName

  useSearchProcedureOrdenation().value = SearchProcedureOrdenationMapping[SearchProcedureOrdenation.RELEVANCE]

  emit('selectLocation')
}

onNuxtReady(() => {
  term.value = useLocation().value?.showName || ''
})
</script>

<template>
  <div class="w-full">
    <label v-if="label" class="text-secondary-300 mb-1 text-sm font-sora">{{ label }}</label>

    <PrimeAutoComplete
      v-model="term"
      :suggestions="cities as ILocation[]"
      placeholder="Buscar por CEP ou Cidade"
      fluid
      :min-length="3"
      auto-option-focus
      :invalid="locationInvalid"
      empty-search-message="Nenhum resultado encontrado. Tente pesquisar outro item ou em uma área diferente."
      option-label="showName"
      @option-select="handleSelect"
    />

    <input
      v-model="term"
      v-maska
      :data-maska="mask"
      type="hidden"
    />
  </div>
</template>
